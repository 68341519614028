export default class riskSurveyService {
  async getAll()  {
    return fetch('/api/riskSurvey/getRiskSurveys', { method: 'GET', mode: 'cors', credentials: 'include' })
    .then(response => { 
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json(); 
    })
    .catch( (error) => {
      console.error(error);
      throw Error(error);
    })

  }

  async get(riskSurveyId) {
     return fetch(`/api/riskSurvey/getRiskSurvey?riskSurveyId=${riskSurveyId}`, { method: 'GET', mode: 'cors', credentials: 'include' })
     .then(response => { 
      if (!response.ok) {
        if (response.status === 401)
          return { error: 'access'} 

        throw Error(response.statusText);
      }
      return response.json(); 
    })
    .catch( (error) => {
      console.error(error);
      throw Error(error);
    })
  }

  async getTemplate(institutionId, riskSurveyTypeId, moduleId) {
    return fetch(`/api/riskSurvey/getTemplate?institutionId=${institutionId}&riskSurveyTypeId=${riskSurveyTypeId}&moduleId=${moduleId}`, { method: 'GET', mode: 'cors', credentials: 'include' })
    .then(response => { 
     if (!response.ok)
       throw Error(response.statusText);
     return response.json(); 
   })
   .catch( (error) => {
     console.error(error);
     throw Error(error);
   })
 }

  async downloadExcel(riskSurveyId) {
    window.location = `/api/riskSurvey/downloadExcel?riskSurveyId=${riskSurveyId}`
  }

  async downloadPdf(riskSurveyId, fileName){
    window.open(`/api/riskSurvey/downloadPdf?riskSurveyId=${riskSurveyId}&pdf=${encodeURIComponent(fileName)}`)
  }

  async downloadManagedExcel() {
    window.location = '/api/riskSurvey/downloadManagedExcel'
  }

  async create(riskSurvey) {
    return fetch('/api/riskSurvey/createRiskSurvey', 
      { method: 'POST', 
        mode: 'cors', 
        credentials: 'include',
        body: JSON.stringify(riskSurvey),
        json: true,
        headers: {'Accept': 'application/json','Content-Type': 'application/json'}
      }
    )
    .then(response => { 
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json(); 
    })
    .catch( (error) => {
      console.error(error);
      throw Error(error);
    })

  }

  async update(riskSurvey) {
    return fetch('/api/riskSurvey/updateRiskSurvey', 
      { method: 'POST', 
        mode: 'cors', 
        credentials: 'include',
        body: JSON.stringify(riskSurvey),
        json: true,
        headers: {'Accept': 'application/json','Content-Type': 'application/json'}
      }
    )
    .then(response => { 
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.ok; 
    })
    .catch( (error) => {
      console.log(error);
      throw Error(error);
    })
  }

  async delete(riskSurvey) {
    return fetch('/api/riskSurvey/deleteRiskSurvey', 
      { method: 'POST', 
        mode: 'cors', 
        credentials: 'include',
        body: JSON.stringify(riskSurvey),
        json: true,
        headers: {'Accept': 'application/json','Content-Type': 'application/json'}
      }
    )
    .then(response => { 
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.ok; 
    })
    .catch( (error) => {
      console.log(error);
      throw Error(error);
    })
  }



  async close(riskSurvey){
    return fetch('/api/riskSurvey/close', 
    { method: 'POST', 
      mode: 'cors', 
      credentials: 'include',
      body: JSON.stringify(riskSurvey),
      json: true,
      headers: {'Accept': 'application/json','Content-Type': 'application/json'}
    }
    )
    .then(response => { 
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.ok; 
    })
    .catch( (error) => {
      console.error(error);
      throw Error(error);
    })
  }

  download(riskSurveyId){
    window.open(`/api/riskSurvey/download?riskSurveyId=${riskSurveyId}`)
  }

  downloadFinalReport(riskSurveyId){
    window.open(`/api/riskSurvey/downloadFinalReport?riskSurveyId=${riskSurveyId}`)
  }

  publish(riskSurveyId){
    return fetch('/api/riskSurvey/updateRiskSurvey', 
      { method: 'POST', 
        mode: 'cors', 
        credentials: 'include',
        body: JSON.stringify({riskSurveyId, isPublishToTeam: 1}),
        json: true,
        headers: {'Accept': 'application/json','Content-Type': 'application/json'}
      }
    )
    .then(response => { 
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.ok; 
    })
    .catch( (error) => {
      console.log(error);
      throw Error(error);
    })
  }

  
  notify(riskSurveyId){
    return fetch(`/api/riskSurvey/notify?riskSurveyId=${riskSurveyId}`, {
      method: 'GET',
      mode: 'cors',
      credentials: 'include',
      json: true,
      headers: {'Accept': 'application/json','Content-Type': 'application/json'}
    })
    .then(response => { 
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.ok; 
    })
    .catch( (error) => {
      console.error(error);
      throw Error(error);
    })
  }

  email(riskSurveyId, email) {
    return fetch(`/api/riskSurvey/emailRiskSurvey`, 
      { method: 'POST', 
        mode: 'cors', 
        credentials: 'include',
        body: JSON.stringify({riskSurveyId, email}),
        json: true,
        headers: {'Accept': 'application/json','Content-Type': 'application/json'}
      }
    )
    .then(response => { 
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.ok;  
    })
    .catch( (error) => {
      console.error(error);
      throw Error(error);
    })
  }

  async saveModuleQuestionAnswers(module) {
    return fetch('/api/riskSurvey/saveModuleQuestionAnswers', 
      { method: 'POST', 
        mode: 'cors', 
        credentials: 'include',
        body: JSON.stringify(module),
        json: true,
        headers: {'Accept': 'application/json','Content-Type': 'application/json'}
      }
    )
    .then(response => { 
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.ok; 
    })
    .catch( (error) => {
      console.log(error);
      throw Error(error);
    })
  }

  async saveModuleQuestionAnswer(answer) {
    return fetch('/api/riskSurvey/saveModuleQuestionAnswer', 
      { method: 'POST', 
        mode: 'cors', 
        credentials: 'include',
        body: JSON.stringify(answer),
        json: true,
        headers: {'Accept': 'application/json','Content-Type': 'application/json'}
      }
    )
    .then(response => { 
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.ok; 
    })
    .catch( (error) => {
      console.log(error);
      throw Error(error);
    })
  }

  async saveModuleNote(note) {
    return fetch('/api/riskSurvey/saveModuleNote', 
      { method: 'POST', 
        mode: 'cors', 
        credentials: 'include',
        body: JSON.stringify(note),
        json: true,
        headers: {'Accept': 'application/json','Content-Type': 'application/json'}
      }
    )
    .then(response => { 
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.ok; 
    })
    .catch( (error) => {
      console.log(error);
      throw Error(error);
    })
  }

  async saveModuleData(data) {
    return fetch('/api/riskSurvey/saveModuleData', 
      { method: 'POST', 
        mode: 'cors', 
        credentials: 'include',
        body: JSON.stringify(data),
        json: true,
        headers: {'Accept': 'application/json','Content-Type': 'application/json'}
      }
    )
    .then(response => { 
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.ok; 
    })
    .catch( (error) => {
      console.log(error);
      throw Error(error);
    })
  }


  async deleteModuleQuestionAnswer(riskSurveyModuleQuestionAnswerId) {
    return fetch('/api/riskSurvey/deleteModuleQuestionAnswer', 
      { method: 'POST', 
        mode: 'cors', 
        credentials: 'include',
        body: JSON.stringify({riskSurveyModuleQuestionAnswerId}),
        json: true,
        headers: {'Accept': 'application/json','Content-Type': 'application/json'}
      }
    )
    .then(response => { 
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.ok; 
    })
    .catch( (error) => {
      console.log(error);
      throw Error(error);
    })
  }

  async getAssetNames(riskSurveyId, moduleId){
    return fetch(`/api/riskSurvey/asset/getAssetNames?riskSurveyId=${riskSurveyId}&moduleId=${moduleId || ''}`, {
      method: 'GET',
      mode: 'cors',
      credentials: 'include',
      json: true,
      headers: {'Accept': 'application/json','Content-Type': 'application/json'}
    })
    .then(response => { 
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json(); 
    })
    .catch( (error) => {
      console.error(error);
      throw Error(error);
    })
  }

  async uploadFile(riskSurveyId, moduleId, file, notes){
    return fetch(`/api/riskSurvey/asset/uploadFile?riskSurveyId=${riskSurveyId}&moduleId=${moduleId}&notes=${notes || ''}`, {
      method: 'POST',
      mode: 'cors', 
      credentials: 'include',
      body: file,
    })
    .then((response) => {
      return response
    }) 
    .catch( (error) => {
      console.error(error);
      throw Error(error);
    })
  }

  downloadAsset(assetId){
    window.open(`/api/riskSurvey/asset/getAsset?assetId=${assetId}`)
  }


  async deleteAsset(assetId){
    return fetch('/api/riskSurvey/asset/deleteAsset', {
      method: 'POST',
      mode: 'cors', 
      credentials: 'include',
      json: true,
      body: JSON.stringify({ assetId }),
      headers: {'Accept': 'application/json','Content-Type': 'application/json'}
    })
    .then((response) => {
      return response
    }) 
    .catch( (error) => {
      console.error(error);
      throw Error(error);
    })
  }


  async getModuleQuestionAssetNames(riskSurveyId, questionId){
    return fetch(`/api/riskSurvey/asset/getModuleQuestionAssetNames?riskSurveyId=${riskSurveyId}&questionId=${questionId || ''}`, {
      method: 'GET',
      mode: 'cors',
      credentials: 'include',
      json: true,
      headers: {'Accept': 'application/json','Content-Type': 'application/json'}
    })
    .then(response => { 
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json(); 
    })
    .catch( (error) => {
      console.error(error);
      throw Error(error);
    })
  }

  async moduleQuestionUploadFile(riskSurveyId, questionId, file){
    return fetch(`/api/riskSurvey/asset/moduleQuestionUploadFile?riskSurveyId=${riskSurveyId}&questionId=${questionId}`, {
      method: 'POST',
      mode: 'cors', 
      credentials: 'include',
      body: file,
    })
    .then((response) => {
      return response
    }) 
    .catch( (error) => {
      console.error(error);
      throw Error(error);
    })
  }

  getModuleQuestionAsset(assetId){
    window.open(`/api/riskSurvey/asset/getModuleQuestionAsset?assetId=${assetId}`)
  }


  async deleteModuleQuestionAsset(assetId){
    return fetch('/api/riskSurvey/asset/deleteModuleQuestionAsset', {
      method: 'POST',
      mode: 'cors', 
      credentials: 'include',
      json: true,
      body: JSON.stringify({ assetId }),
      headers: {'Accept': 'application/json','Content-Type': 'application/json'}
    })
    .then((response) => {
      return response
    }) 
    .catch( (error) => {
      console.error(error);
      throw Error(error);
    })
  }
}